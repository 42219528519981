import en from './locale/en';
import es from './locale/es';
import pt from './locale/pt';
import pt_BR from './locale/pt_BR';
import agenda from './locale/pt/agenda.json';
import agendaEn from './locale/en/agenda.json';

const messages = {
  en: {
    ...en,
    ...agendaEn
  },
  es,
  pt: {
    ...pt,
    ...agenda
  },
  pt_BR
};

export default messages;
