import { frontendURL } from '../../../../helper/URLHelper';

const agenda = accountId => ({
  parentNav: 'agenda',
  routes: [
    'agenda_view',
    'agenda_team',
    'agenda_availability',
    'agenda_settings'
  ],
  menuItems: [
    {
      icon: 'calendar',
      label: 'AGENDA_VIEW',
      key: 'agenda_view',
      toState: frontendURL(`accounts/${accountId}/agenda`),
      toolTip: 'Visualizar agenda',
      toStateName: 'agenda_view'
    },
    {
      icon: 'people',
      label: 'AGENDA_TEAM',
      key: 'agenda_team',
      toState: frontendURL(`accounts/${accountId}/agenda/team`),
      toStateName: 'agenda_team'
    },
    {
      icon: 'calendar',
      label: 'AGENDA_AVAILABILITY',
      key: 'agenda_availability',
      toState: frontendURL(`accounts/${accountId}/agenda/availability`),
      toStateName: 'agenda_availability'
    },
    {
      icon: 'settings',
      label: 'AGENDA_SETTINGS',
      key: 'agenda_settings',
      toState: frontendURL(`accounts/${accountId}/agenda/settings`),
      toStateName: 'agenda_settings'
    }
  ]
});

export default agenda; 