/* global axios */

import ApiClient from './ApiClient';

class AgendaAPI extends ApiClient {
  constructor() {
    super('appointments', { accountScoped: true });
  }

  getAppointments(accountId, params = {}) {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    
    return axios.get(`/api/v1/accounts/${accountId}/appointments`, {
      params: {
        start_date: params.start_date,
        end_date: params.end_date
      }
    });
  }

  createAppointment(accountId, data) {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    
    return axios.post(`/api/v1/accounts/${accountId}/appointments`, {
      appointment: data
    });
  }

  updateAppointment(accountId, id, data) {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    
    return axios.patch(`/api/v1/accounts/${accountId}/appointments/${id}`, {
      appointment: data
    });
  }

  deleteAppointment(accountId, id) {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    
    return axios.delete(`/api/v1/accounts/${accountId}/appointments/${id}`);
  }
}

export default new AgendaAPI(); 