import { FEATURE_FLAGS } from '../../featureFlags';
import types from '../mutation-types';
import agendaAPI from '../../api/agenda';

const state = {
  isEnabled: false,
  eventos: [],
  uiFlags: {
    isFetching: false,
    isError: false
  },
  configuracoes: {
    notificacoes: {
      email: true,
      push: true,
    },
    antecedenciaNotificacao: 15,
    visualizacaoPadrao: 'mes',
    primeiroDiaSemana: 1,
    horariosDisponiveis: [],
    diasDisponiveis: [],
  }
};

const getters = {
  isAgendaEnabled: state => state.isEnabled,
  getEventos: state => state.eventos,
  getConfiguracoes: state => state.configuracoes,
  getUIFlags: state => state.uiFlags
};

const getCorEvento = (tipo) => {
  const cores = {
    esporte: '#0ea5e9',
    trabalho: '#22c55e',
    casa: '#6366f1',
    escola: '#0ea5e9'
  };
  return cores[tipo] || '#64748b';
};

const actions = {
  async fetchEventos({ commit }, { accountId, params }) {
    if (!accountId) return;
    
    commit('SET_UI_FLAG', { isFetching: true });
    try {
      const { data } = await agendaAPI.getAppointments(accountId, params);
      const eventos = (data?.events || []).map(evento => ({
        id: evento.id,
        titulo: evento.title,
        data: new Date(evento.start_date),
        tipo: evento.event_type,
        cor: evento.color || getCorEvento(evento.event_type),
        descricao: evento.description,
        status: evento.status
      }));
      commit(types.SET_AGENDA_EVENTOS, eventos);
    } catch (error) {
      commit('SET_UI_FLAG', { isError: true });
      throw error;
    } finally {
      commit('SET_UI_FLAG', { isFetching: false });
    }
  },

  setAgendaEnabled({ commit }, enabled) {
    commit(types.SET_AGENDA_ENABLED, enabled);
  },
  
  adicionarEvento({ commit, state }, evento) {
    const eventos = [...state.eventos, evento];
    commit(types.SET_AGENDA_EVENTOS, eventos);
  },

  atualizarConfiguracoes({ commit }, config) {
    commit(types.SET_AGENDA_CONFIGURACOES, config);
  },
};

const mutations = {
  [types.SET_AGENDA_ENABLED](state, enabled) {
    state.isEnabled = enabled;
  },
  
  [types.SET_AGENDA_EVENTOS](state, eventos) {
    state.eventos = eventos;
  },

  [types.SET_AGENDA_CONFIGURACOES](state, config) {
    state.configuracoes = {
      ...state.configuracoes,
      ...config
    };
  },

  SET_UI_FLAG(state, flag) {
    state.uiFlags = {
      ...state.uiFlags,
      ...flag
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}; 